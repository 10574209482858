import { Layout, Button } from "@/components";
import { routerMap } from "@/router/agent_router";
import { Tabs } from "antd";
import { LibAuthCode } from "lib/Auth";
import React from "react";
import SaleReturnOrder from './SaleReturnOrder';
import SaleReturnProduct from './SaleReturnProduct';

export default class SaleReturnPage extends React.PureComponent<RouterPropsType> {

  private list: SaleReturnOrder | null = null;
  private product: SaleReturnProduct | null = null;

  render() {
    return <Tabs className="app-tabs" animated={false} tabBarExtraContent={
      <Layout.TabBarExtra>
        <Button type="primary" authCode={LibAuthCode.saleReturnOrder.create} onClick={() => this.props.history.push(routerMap["saleReturn.create"].path)}>新增订货退货单</Button>
        <Button.Refresh onClick={() => {
          this.list?.onQueryChange();
          this.product?.onQueryChange();
        }}></Button.Refresh>
      </Layout.TabBarExtra>
    }>
      <Tabs.TabPane key="1" tab="订货退货单">
        <SaleReturnOrder history={this.props.history} ref={r => this.list = r}/>
      </Tabs.TabPane>
      <Tabs.TabPane key="2" tab="订货退货商品">
        <SaleReturnProduct history={this.props.history}  ref={r => this.product = r}/>
      </Tabs.TabPane>
    </Tabs>
  }
}
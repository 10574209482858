import React from 'react';
import {Dialog, ViewTable, Layout, Filter, Link, Column} from '@/components';
import { routerMap } from '@/router/agent_router';
import { SALE_RETURN_STATUS } from '@/enum-data/enum';
import LibEnum from 'lib/enum';
import LibUtils from 'lib/utils';
import { LibAuthCode } from 'lib/Auth';
import WebApi from '@/web/api';

export default class SaleReturnOrder extends React.PureComponent<RouterPropsType> {

  private table: ViewTable | null = null
  private params = {}

  state = {
    visible: false,
  }

  onQueryChange = () => {
    this.table?.reset(this.params);
  };

  render() {
    return (
      <Layout.TabPage>
        {/* 搜索条件 */}
        <Filter
          fields={[
            {
              type: 'select',
              field: 'orderStatus',
              name: '订单状态',
              options: LibUtils.enumToOptions(LibEnum.SALE_ORDER_RETURN_STATUS),
            },
            {
              type: 'select',
              field: 'paymentStatus',
              name: '付款状态',
              options: LibUtils.enumToOptions(LibEnum.PAYMENT_STATUS_3),
            },
            {
              type: 'input',
              field: 'keyword',
              name: '搜索',
              placeholder: '客户名称',
            },
          ]}
          onChange={values => {
            this.params = values;
            this.onQueryChange();
          }}
        />

        {/* table 数据 */}
        <ViewTable
          flex1
          ref={r => this.table = r}
          fetch={(data) => {
            return WebApi.sale_return_order_paging(data);
          }}
          actionButtons={[
            {
              type: 'invalid',
              authCode: LibAuthCode.saleReturnOrder.update,
              disabled: row => row.orderStatus !== SALE_RETURN_STATUS.WAIT_AUDIT.value,
              onClick: (row) => {
                if (row.orderStatus !== SALE_RETURN_STATUS.WAIT_AUDIT.value) {
                  Dialog.warning({
                    title: '不可作废',
                    content: '只有待审核订单才可以作废！'
                  });
                  return;
                }
                this.clickInvalid(row);
              }
            },
            {
              type: 'delete',
              authCode: LibAuthCode.saleReturnOrder.delete,
              disabled: row => row.orderStatus !== SALE_RETURN_STATUS.INVALID.value,
              onClick: (row) => {
                if (row.orderStatus !== SALE_RETURN_STATUS.INVALID.value) {
                  Dialog.warning({
                    title: '不可删除',
                    content: '只有作废的订单才可以删除！'
                  });
                  return;
                }
                this.clickDelete(row);
              }
            }
          ]}
          columns={[
            {
              title: '退货单号',
              dataIndex: 'id',
              width: 200,
              align: 'center',
              render: (text, record) => (
                <Link to={routerMap.saleReturnOrderDetail.getPath(record.id)}>{text}</Link>
              ),
            },
            {
              title: '客户',
              dataIndex: 'customerName',
              width: 200,
            },
            {
              title: '订单状态',
              dataIndex: 'orderStatusDesc',
              width: 150,
              align: 'center',
              render: (text, record) => {
                if (record.orderStatus === LibEnum.SALE_ORDER_RETURN_STATUS.WAIT_AUDIT.value) {
                  return <Column.OrderStatus status="WAIT_AUDIT">{text}</Column.OrderStatus>
                }
                if (record.orderStatus === LibEnum.SALE_ORDER_RETURN_STATUS.WAIT_IN_STOCK.value) {
                  return <Column.OrderStatus status="WAIT_IN_STOCK">{text}</Column.OrderStatus>
                }
                if (record.orderStatus === LibEnum.SALE_ORDER_RETURN_STATUS.INVALID.value) {
                  return <Column.OrderStatus status="INVALID">{text}</Column.OrderStatus>
                }
                if (record.orderStatus === LibEnum.SALE_ORDER_RETURN_STATUS.DONE.value) {
                  return <Column.OrderStatus status="DONE">{text}</Column.OrderStatus>
                }
                return text
              },
            },
            {
              title: '付款状态',
              dataIndex: 'paymentStatusDesc',
              width: 150,
              align: 'center',
              render: (text, record) => {
                return <Column.PaymentStatus completed={record.paymentStatus === LibEnum.PAYMENT_STATUS.FINISHED.value}>{text}</Column.PaymentStatus>
              },
            },
            {
              title: '下单时间',
              dataIndex: 'datetime',
              align: 'center',
              width: 200,
            },
            {
              title: '应退金额',
              dataIndex: 'amount',
              align: 'right',
              width: 150,
            },
            {
              title: '商品数量',
              dataIndex: 'productCount',
              width: 150,
              align: 'right'
            },
            {
              title: '备注',
              dataIndex: 'remark',
              width: 240,
            },
          ]}
          rowKey={row => row.id}/>
      </Layout.TabPage>
    )
  }

  // 作废订单
  clickInvalid = record => {
    Dialog.confirm({
      content: '确认作废订单吗？',
      onOk: async() => {
        WebApi.sale_return_order_cancel({
          saleReturnOrderId: record.id,
        })
        .then(() => {
          showSuccess.save();
          this.table?.refresh();
        })
        .catch(e => {
          showError(e.message);
        });
      },
    });
  }

  // 删除订单
  clickDelete = record => {
    Dialog.confirm({
      content: `确认删除订单 “${record.saleReturnId}” 吗？`,
      onOk: async () => {
        WebApi.sale_return_order_delete({
          saleReturnOrderId: record.saleReturnId,
        })
        .then(() => {
          showSuccess.delete();
          this.table?.refresh();
        })
        .catch(e => {
          showError(e.message);
        });
      }
    });
  }
}
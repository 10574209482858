import React from 'react';
import {Layout, Filter, ViewTable, Link} from '@/components';
import apis from '@/api/api';
import { routerMap } from '@/router/agent_router';
import WebApi from '@/web/api';

export default class PurchaseReturnProducts extends React.PureComponent<RouterPropsType> {

  private table: ViewTable | null = null
  private params = {}

  state = {
    visible: false,
  }

  onQueryChange = () => {
    this.table?.reset(this.params);
  };

  render() {

    return (
      <Layout.TabPage>
        <Filter
          fields={[
            {
              type: 'category',
            },
            {
              type: 'input',
              field: 'keyword',
              name: '搜索',
              placeholder: '商品名称',
            },
          ]}
          onChange={values => {
            this.params = values;
            this.onQueryChange();
          }}
        />

        {/* table 数据 */}
        <ViewTable
          flex1
          ref={r => this.table = r}
          fetch={data => {
            return WebApi.sale_return_product_paging(data);
          }}
          columns={[
            {
              title: '退货单号',
              dataIndex: 'saleReturnId',
              align: 'center',
              width: 200,
              render: (text, record) => (
                <Link to={routerMap.saleReturnOrderDetail.getPath(record.saleReturnId)}>{text}</Link>
              ),
            },
            {
              title: '商品',
              dataIndex: 'productName',
              width: 200,
            },
            {
              title: '规格',
              dataIndex: 'skuName',
              width: 300,
            },
            {
              title: '退货价格',
              dataIndex: 'price',
              width: 150,
              align: 'right'
            },
            {
              title: '数量',
              dataIndex: 'count',
              width: 150,
              align: 'right'
            },
            {
              title: '退货金额',
              dataIndex: 'amount',
              align: 'right',
              width: 150,
            },
            {
              title: '下单时间',
              dataIndex: 'createdAt',
              align: 'center',
              width: 200,
            },
            {
              title: '备注',
              dataIndex: 'remark',
              width: 240,
            },
          ]}
          rowKey={row => row.id}/>
      </Layout.TabPage>
    )
  }
}